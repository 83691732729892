/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Block from "../components/block"
import Spacer from "../components/spacer"
import ContainerImage from "../components/container-image"

const BitbitCashContent = () => {
  const data = useStaticQuery(graphql`
    query {
      image01: file(relativePath: { eq: "bitbit-cash/view-mobile-dashboard.jpg" }) {
        ...imageLargeHeroProp
      }
      image02: file(relativePath: { eq: "bitbit-cash/view-mobile-screens.jpg" }) {
        ...imageLargeHeroProp
      }
      image03: file(relativePath: { eq: "bitbit-cash/view-icons.jpg" }) {
        ...imageLargeHeroProp
      }
      image04: file(relativePath: { eq: "bitbit-cash/view-mobile-angle.jpg" }) {
        ...imageLargeHeroProp
      }
      image05: file(relativePath: { eq: "bitbit-cash/view-web1.jpg" }) {
        ...imageLargeHeroProp
      }
      image06: file(relativePath: { eq: "bitbit-cash/view-web2.jpg" }) {
        ...imageLargeHeroProp
      }
      image07: file(relativePath: { eq: "bitbit-cash/view-web3.jpg" }) {
        ...imageLargeHeroProp
      }
      image08: file(relativePath: { eq: "bitbit-cash/view-web4.jpg" }) {
        ...imageLargeHeroProp
      }
    }
  `)

  return (
    <>
      <Block>
        <p>
          <OutboundLink href="https://bitbit.cash">Bitbit Cash</OutboundLink> is an app that allows
          anyone to send and receive money, and enjoy the convenience of Bitcoin without the price
          volatility associated with it. It's available on the web, iOS, and Android. The mobile's
          user interface adheres to design guidelines and the web app designs based on{" "}
          <Link to="/work/rebit-web/">Rebit's web concept</Link>. Leveraging these building blocks
          helped us improve the design and development aspects.
        </p>
        <p>
          SCI, the parent company, launched the app in June 2015 as one of its flagship products and
          a milestone to the blockchain ecosystem.
        </p>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Bitbit Cash - Home, Profile and Actions screens in iOS"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image01.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Bitbit Cash - Screen design collage"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image02.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Bitbit Cash - User interface and icon design"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image03.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#5C25CF">
        <Img
          backgroundColor="#5C25CF"
          alt="Bitbit Cash - Wallet app visual design"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image04.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Bitbit Cash - Website landing page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image05.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Bitbit Cash - Web app Dashboard page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image06.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Bitbit Cash - Web app Transaction page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image07.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Bitbit Cash - Web app screen collage"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image08.childImageSharp.fluid}
        />
      </ContainerImage>
    </>
  )
}

export default BitbitCashContent
