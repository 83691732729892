/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Block from "../components/block"
import Caption from "../components/caption"
import Spacer from "../components/spacer"
import ContainerImage from "../components/container-image"

const OrientExpressGlobalContent = () => {
  const data = useStaticQuery(graphql`
    query {
      image01: file(relativePath: { eq: "orient-express-global/view-logo1.jpg" }) {
        ...imageLargeHeroProp
      }
      image02: file(relativePath: { eq: "orient-express-global/view-logo2.jpg" }) {
        ...imageLargeHeroProp
      }
      image03: file(relativePath: { eq: "orient-express-global/view-logo3.jpg" }) {
        ...imageLargeHeroProp
      }
      image04: file(relativePath: { eq: "orient-express-global/view-pages1.jpg" }) {
        ...imageLargeHeroProp
      }
      image05: file(relativePath: { eq: "orient-express-global/view-pages2.jpg" }) {
        ...imageLargeHeroProp
      }
    }
  `)

  return (
    <>
      <Block>
        <p>
          A logo design coupled with a style guide to conceive a document that solves issues and
          makes informed decisions. The logotype concept's outcome is the arrows pointing at the
          center and propeller-like symbol, but its essence is to move the ideas in a direction.
          Opted a relatable illustration to elevate the document's thoughts. Designs converted and
          accessible to Google Slides to enable the partners to collaborate.
        </p>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#0C1329">
        <Img
          backgroundColor="#0C1329"
          alt="Orient Express Global - Logo concepts"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image01.childImageSharp.fluid}
        />
        <Caption>Logo concept design for Orient Express Global.</Caption>
      </ContainerImage>

      <ContainerImage bgColor="#0C1329">
        <Img
          backgroundColor="#0C1329"
          alt="Orient Express Global - Logo selected"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image02.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#0C1329">
        <Img
          backgroundColor="#0C1329"
          alt="Orient Express Global - Visual layout design"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image03.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#CED5E4">
        <Img
          backgroundColor="#CED5E4"
          alt="Orient Express Global - Inside pages illustration and design"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image04.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#0297e3">
        <Img
          backgroundColor="#0297e3"
          alt="Orient Express Global - Inside pages perspective"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image05.childImageSharp.fluid}
        />
      </ContainerImage>
    </>
  )
}

export default OrientExpressGlobalContent
