import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { breakPoints, grid, gutter, toRem } from "../styles/utils"
import Container from "./container"

const TextBlock = styled.div`
  ${gutter};
  margin-bottom: ${toRem(15)};

  @media (min-width: ${breakPoints.medium}) {
    width: ${grid(10, 12)};
  }

  @media (min-width: ${breakPoints.large}) {
    width: ${grid(8, 12)};
  }

  /* @media (min-width: ${breakPoints.jumbo}) {
    width: ${grid(9, 12)};
  } */
`

const Block = ({ children }) => (
  <Container>
    <TextBlock>{children}</TextBlock>
  </Container>
)

Block.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Block
