import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { breakPoints, maxWidth, toRem } from "../styles/utils"

const FlexBox = styled.div`
  display: flex;
  max-width: ${maxWidth};
  margin: 0 auto;
  padding-right: ${toRem(10)};
  padding-left: ${toRem(10)};
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.align};
  justify-content: center;

  @media (min-width: ${breakPoints.medium}) {
    padding-right: ${toRem(15)};
    padding-left: ${toRem(15)};
  }
`

const Container = ({ children, ...props }) => {
  const { id, className, direction, align } = props

  return (
    <FlexBox id={id} className={className} direction={direction} align={align}>
      {children}
    </FlexBox>
  )
}

Container.defaultProps = {
  id: null,
  className: null,
  direction: "row",
  align: "stretch",
}

Container.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  direction: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Container
