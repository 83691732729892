/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Block from "../components/block"
import Caption from "../components/caption"
import Spacer from "../components/spacer"
import ContainerImage from "../components/container-image"

const RebitMobileContent = () => {
  const data = useStaticQuery(graphql`
    query {
      image01: file(relativePath: { eq: "rebit-mobile/view-transfer-funds.jpg" }) {
        ...imageLargeHeroProp
      }
      image02: file(relativePath: { eq: "rebit-mobile/view-screens.jpg" }) {
        ...imageLargeHeroProp
      }
      image03: file(relativePath: { eq: "rebit-mobile/view-visual-design.jpg" }) {
        ...imageLargeHeroProp
      }
      image04: file(relativePath: { eq: "rebit-mobile/view-illustrations.jpg" }) {
        ...imageLargeHeroProp
      }
      image05: file(relativePath: { eq: "rebit-mobile/view-rebittance.jpg" }) {
        ...imageLargeHeroProp
      }
    }
  `)

  return (
    <>
      <Block>
        <p>
          Originally, <OutboundLink href="https://rebit.ph">Rebit</OutboundLink> is a web app and a
          flagship product of SCI. It allows anyone to transfer money to the Philippines from
          anywhere in the world using Bitcoins. Aside from it, the team took the initiative to
          explored a design and development for mobile platform availability and teases opportunity
          to create a modular product for business cases. Instead of creating new user interface
          components and illustrations, we carried out the{" "}
          <Link to="/work/rebit-web">previous</Link> design system tailored to this objective and
          cut down design time to focus more on validating the user flow.
        </p>
        <p>
          The team delivered and presented a working MVP product with a backend ready for business
          operation. Notable key features: the seamless KYC automation verification and leveraging
          partners network.
        </p>
        <Caption>Project Status: Unknown</Caption>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#D4DCE7">
        <Img
          backgroundColor="#D4DCE7"
          alt="Rebit Mobile - Screens showing transactions"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image01.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#D4DCE7">
        <Img
          backgroundColor="#D4DCE7"
          alt="Rebit Mobile - Screen design collage"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image02.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#00BE92">
        <Img
          backgroundColor="#00BE92"
          alt="Rebit Mobile - Screen design collage"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image03.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#D4DCE7">
        <Img
          backgroundColor="#D4DCE7"
          alt="Rebit Mobile - In app illustrations"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image04.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#00BE92">
        <Img
          backgroundColor="#00BE92"
          alt="Rebit Mobile - Visual illustrations"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image05.childImageSharp.fluid}
        />
        <Caption>Repurposed an illustration that I made a while back.</Caption>
      </ContainerImage>
    </>
  )
}

export default RebitMobileContent
