import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { breakPoints, gutter, toRem } from "../styles/utils"
import Container from "./container"

const ImageItem = styled.div`
  ${gutter};
  display: block;
  width: 100%;
  margin-bottom: ${toRem(40)};

  .gatsby-resp-image-wrapper,
  .gatsby-image-wrapper {
    background-color: ${(props) => props.bgColor};
  }

  small {
    margin-bottom: ${toRem(-15)};
    margin-top: ${toRem(8)};
    position: relative;
    display: block;
    line-height: 1.2;
  }

  @media (min-width: ${breakPoints.medium}) {
    small {
      text-align: center;
      margin-bottom: ${toRem(-20)};
      margin-top: ${toRem(10)};
    }
  }

  @media (min-width: ${breakPoints.large}) {
    margin-bottom: ${toRem(80)};
    small {
      margin-bottom: ${toRem(-30)};
      margin-top: ${toRem(10)};
    }
  }
`

const ContainerImage = ({ children, bgColor }) => (
  <Container>
    <ImageItem bgColor={bgColor}>{children}</ImageItem>
  </Container>
)

ContainerImage.defaultProps = {
  bgColor: "transparent",
}

ContainerImage.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ContainerImage
