import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Caption = ({ children }) => {
  const CaptionItem = styled.small`
    color: var(--gray-lighter);
    display: inline-block;
  `
  return <CaptionItem>{children}</CaptionItem>
}

Caption.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Caption
