import styled from "@emotion/styled"
import { breakPoints, toRem } from "../styles/utils"

const Spacer = styled.span`
  display: block;
  margin-bottom: ${toRem(25)};

  @media (min-width: ${breakPoints.large}) {
    margin-bottom: ${toRem(65)};
  }
`

export default Spacer
