/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Block from "../components/block"
import Caption from "../components/caption"
import Spacer from "../components/spacer"
import ContainerImage from "../components/container-image"

const SatoshiCitadelIndustriesContent = () => {
  const data = useStaticQuery(graphql`
    query {
      image01: file(relativePath: { eq: "satoshi-citadel-industries/view-logo1.jpg" }) {
        ...imageLargeHeroProp
      }
      image02: file(relativePath: { eq: "satoshi-citadel-industries/view-logo2.jpg" }) {
        ...imageLargeHeroProp
      }
      image03: file(relativePath: { eq: "satoshi-citadel-industries/view-iconography.jpg" }) {
        ...imageLargeHeroProp
      }
      image04: file(relativePath: { eq: "satoshi-citadel-industries/view-styleguide.jpg" }) {
        ...imageLargeHeroProp
      }
      image05: file(relativePath: { eq: "satoshi-citadel-industries/view-desktop.jpg" }) {
        ...imageLargeHeroProp
      }
      image06: file(relativePath: { eq: "satoshi-citadel-industries/view-mobile.jpg" }) {
        ...imageLargeHeroProp
      }
      image07: file(relativePath: { eq: "satoshi-citadel-industries/view-event.jpg" }) {
        ...imageLargeHeroProp
      }
      image08: file(relativePath: { eq: "satoshi-citadel-industries/view-theme.jpg" }) {
        ...imageLargeHeroProp
      }
    }
  `)

  return (
    <>
      <Block>
        <p>
          Satoshi Citadel Industries, <OutboundLink href="https://sci.ph">SCI</OutboundLink> is a
          technology startup company that seeks to streamline key services in the Philippines. With
          Blockchain-powered solutions empower its product like Bitbit Cash, BuyBitcoin, and Rebit.
          In 2016, it undertook a rebranding together its products. It intends to show growth
          success by expressing a new look while maintaining consistency with its core values,
          mission, and vision.
        </p>
        <p>
          The new logos were simplified for flexibility. It can be used independently (especially
          the symbol) or joined together. The final conceived logo for SCI, the old long wordmark
          changed to a shorter version, and the symbol is inspired by movement, precisely that of
          lightspeed. The Illustrations, iconography, style guides, and other design applications
          followed to consolidate the latest designs.
        </p>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#0C1549">
        <Img
          backgroundColor="#0C1549"
          alt="Satoshi Citadel Industries - Logo concepts"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image01.childImageSharp.fluid}
        />
        <Caption>Some of the logo concept design that I presented to the founders of SCI.</Caption>
      </ContainerImage>

      <ContainerImage bgColor="#E1E3EF">
        <Img
          backgroundColor="#E1E3EF"
          alt="Satoshi Citadel Industries - Business card design"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image02.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E1E3EF">
        <Img
          backgroundColor="#E1E3EF"
          alt="Satoshi Citadel Industries - Iconography design"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image03.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E1E3EF">
        <Img
          backgroundColor="#E1E3EF"
          alt="Satoshi Citadel Industries - Template and style guides"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image04.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E1E3EF">
        <Img
          backgroundColor="#E1E3EF"
          alt="Satoshi Citadel Industries - Website landing page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image05.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E1E3EF">
        <Img
          backgroundColor="#E1E3EF"
          alt="Satoshi Citadel Industries - Website landing page responsive"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image06.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#889fb2">
        <Img
          backgroundColor="#889fb2"
          alt="Satoshi Citadel Industries - Visual for events"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image07.childImageSharp.fluid}
        />
      </ContainerImage>

      <Block>
        <h2>Opportunities for design improvements</h2>
        <p>
          Highlights a handshake illustration idea as a proposal for an opportunity in designs. A
          handshake is a powerful gesture and meaning, which means connecting and respect by
          empowering people. The spectrum from its products' primary color, with its complexity but
          unified, made it possible. In comparison, it's still maintaining the visual salient from
          the prior design.
        </p>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#E1E3EF">
        <Img
          backgroundColor="#E1E3EF"
          alt="Satoshi Citadel Industries - Theme and illustration exploration"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image08.childImageSharp.fluid}
        />
      </ContainerImage>
    </>
  )
}

export default SatoshiCitadelIndustriesContent
