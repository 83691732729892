/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Block from "../components/block"
import Spacer from "../components/spacer"
import ContainerImage from "../components/container-image"

const PrepaidBitcoinContent = () => {
  const data = useStaticQuery(graphql`
    query {
      image01: file(relativePath: { eq: "prepaid-bitcoin/view-screens1.jpg" }) {
        ...imageLargeHeroProp
      }
      image02: file(relativePath: { eq: "prepaid-bitcoin/view-screens2.jpg" }) {
        ...imageLargeHeroProp
      }
      image03: file(relativePath: { eq: "prepaid-bitcoin/view-screens3.jpg" }) {
        ...imageLargeHeroProp
      }
    }
  `)

  return (
    <>
      <Block>
        <p>
          Prepaid Bitcoin aims to let anyone new acquire Bitcoin in fewer interactions and drive
          Bitcoin adoption rapidly. SCI's solution, the parent company, was to develop prepaid
          scratch cards because everyone knows how it works, just like loading your prepaid mobile
          phone. The company then produced the physical cards along with the campaign materials and
          launched the website, a simple straightforward one-pager. Its primary function is to
          validate the cards' redemption with a real-time exchange rate then transfer the amount to
          the wallet, and information on how and where to acquire the cards.
        </p>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#D4DDEA">
        <Img
          backgroundColor="#D4DDEA"
          alt="Prepaid Bitcoin - Website landing page and responsive"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image01.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#D4DDEA">
        <Img
          backgroundColor="#D4DDEA"
          alt="Prepaid Bitcoin - Website card redemption page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image02.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#D4DDEA">
        <Img
          backgroundColor="#D4DDEA"
          alt="Prepaid Bitcoin - Website full view"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image03.childImageSharp.fluid}
        />
      </ContainerImage>
    </>
  )
}

export default PrepaidBitcoinContent
