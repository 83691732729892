import { css } from "@emotion/core"

export const fontSize = 16

export const grid = (column, numberOfColumns) => `${(100 / numberOfColumns) * column}%`

export const toRem = (pixels) => {
  const result = pixels / 16
  return `${result}rem`
}

export const toEm = (points) => {
  const result = points / fontSize
  return `${result}em`
}

export const letterSpacing = (points) => {
  const result = (points + 4) / 1000
  return `-${result}em`
}

export const breakPoints = {
  small: "576px",
  smallMedium: "767.99px",
  medium: "768px",
  mediumLarge: "991.99px",
  large: "992px",
  largeJumbo: "1199.99px",
  jumbo: "1200px",
}

export const maxWidth = "1340px"

export const gutter = css`
  padding-left: var(--small-gutter);
  padding-right: var(--small-gutter);

  @media (min-width: ${breakPoints.medium}) {
    padding-left: var(--medium-gutter);
    padding-right: var(--medium-gutter);
  }
`

export const center = css`
  margin-left: auto;
  margin-right: auto;
`

export const isEmpty = (value) => value === "" && value === null
