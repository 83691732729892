/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Block from "../components/block"
import Caption from "../components/caption"
import Spacer from "../components/spacer"
import ContainerImage from "../components/container-image"

const BlockchainBitcoinGroupContent = () => {
  const data = useStaticQuery(graphql`
    query {
      image01: file(relativePath: { eq: "blockchain-bitcoin-group/view-blockchain1.jpg" }) {
        ...imageLargeHeroProp
      }
      image02: file(relativePath: { eq: "blockchain-bitcoin-group/view-blockchain2.jpg" }) {
        ...imageLargeHeroProp
      }
      image03: file(relativePath: { eq: "blockchain-bitcoin-group/view-bitcoin1.jpg" }) {
        ...imageLargeHeroProp
      }
      image04: file(relativePath: { eq: "blockchain-bitcoin-group/view-bitcoin2.jpg" }) {
        ...imageLargeHeroProp
      }
    }
  `)

  return (
    <>
      <Block>
        <p>
          The SCI founder's mission: "To share our knowledge and expertise with the public and
          private sectors." A logo design and visual identity helps identify them in their
          engagement, newly organization or association, and add positive impression. Philippines'
          flag inspired the logo designs, emphasizing the feature of the equilateral triangle and
          the sun.
        </p>
        <Caption>Credits: John Bailon, Miguel Cuneta, and Jardine Gerodias</Caption>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#828F9B">
        <Img
          backgroundColor="#828F9B"
          alt="Blockchain Association PH - Logo and identity visual concept"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image01.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#828F9B">
        <Img
          backgroundColor="#828F9B"
          alt="Blockchain Association PH - Selected logo"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image02.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#828F9B">
        <Img
          backgroundColor="#828F9B"
          alt="Bitcoin Org - Logo visual design 1"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image03.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#828F9B">
        <Img
          backgroundColor="#828F9B"
          alt="Bitcoin Org - Logo visual design 1"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image04.childImageSharp.fluid}
        />
      </ContainerImage>
    </>
  )
}

export default BlockchainBitcoinGroupContent
