/* eslint-disable react/no-unescaped-entities */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Block from "../components/block"
import Caption from "../components/caption"
import Spacer from "../components/spacer"
import ContainerImage from "../components/container-image"

const FintechForumPhilippinesContent = () => {
  const data = useStaticQuery(graphql`
    query {
      image01: file(relativePath: { eq: "fintech-forum/view-logo1.jpg" }) {
        ...imageLargeHeroProp
      }
      image02: file(relativePath: { eq: "fintech-forum/view-logo2.jpg" }) {
        ...imageLargeHeroProp
      }
      image03: file(relativePath: { eq: "fintech-forum/view-exploration1.jpg" }) {
        ...imageLargeHeroProp
      }
      image04: file(relativePath: { eq: "fintech-forum/view-exploration2.jpg" }) {
        ...imageLargeHeroProp
      }
      image05: file(relativePath: { eq: "fintech-forum/view-exploration3.jpg" }) {
        ...imageLargeHeroProp
      }
    }
  `)

  return (
    <>
      <Block>
        <p>
          A logo design and visual identity for the Philippines' First Inclusive Prosperity Fintech
          Summit. A staircase inspired the final logo concept, not meant for symbolic power but
          value creation; the higher the steps, the more value is achieved. In some way, it's
          signaling a bright future ahead. The design results translated for design applications
          such as posters, banners, etc.
        </p>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#002F69">
        <Img
          backgroundColor="#002F69"
          alt="Fintech Forum Philippines - Logo concepts"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image01.childImageSharp.fluid}
        />
        <Caption>Logo concept we presented to the organization.</Caption>
      </ContainerImage>

      <ContainerImage bgColor="#002F69">
        <Img
          backgroundColor="#002F69"
          alt="Fintech Forum Philippines - Selected logo"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image02.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#002F69">
        <Img
          backgroundColor="#002F69"
          alt="Fintech Forum Philippines - Identity visualization"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image03.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#002F69">
        <Img
          backgroundColor="#002F69"
          alt="Fintech Forum Philippines - Identity visualization"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image04.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#002F69">
        <Img
          backgroundColor="#002F69"
          alt="Fintech Forum Philippines - Identity visualization"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image05.childImageSharp.fluid}
        />
      </ContainerImage>
    </>
  )
}

export default FintechForumPhilippinesContent
