/* eslint-disable react/no-unescaped-entities */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Block from "../components/block"
import Spacer from "../components/spacer"
import ContainerImage from "../components/container-image"

const BuyBitcoinContent = () => {
  const data = useStaticQuery(graphql`
    query {
      image01: file(relativePath: { eq: "buybitcoin/view-landing.jpg" }) {
        ...imageLargeHeroProp
      }
      image02: file(relativePath: { eq: "buybitcoin/view-home-login.jpg" }) {
        ...imageLargeHeroProp
      }
      image03: file(relativePath: { eq: "buybitcoin/view-buy.jpg" }) {
        ...imageLargeHeroProp
      }
      image04: file(relativePath: { eq: "buybitcoin/view-buy-sell.jpg" }) {
        ...imageLargeHeroProp
      }
      image05: file(relativePath: { eq: "buybitcoin/view-dropdown.jpg" }) {
        ...imageLargeHeroProp
      }
      image06: file(relativePath: { eq: "buybitcoin/view-order-created.jpg" }) {
        ...imageLargeHeroProp
      }
      image07: file(relativePath: { eq: "buybitcoin/view-exchange1.jpg" }) {
        ...imageLargeHeroProp
      }
      image08: file(relativePath: { eq: "buybitcoin/view-exchange2.jpg" }) {
        ...imageLargeHeroProp
      }
    }
  `)

  return (
    <>
      <Block>
        <p>
          <OutboundLink href="https://buybitcoin.ph">BuyBitcoin</OutboundLink>, as the name
          suggests, and in contrast, anyone can also sell bitcoin. The first online bitcoin
          broker-dealer in the Philippines launched in 2014 then acquired by SCI and became among
          its flagship products. The latest version design of the website and web app iterated to
          meet the new demands and seamlessly integrate with its blockchain ecosystem.
        </p>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#EAEAF1">
        <Img
          backgroundColor="#EAEAF1"
          alt="BuyBitcoin - Website landing page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image01.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#EAEAF1">
        <Img
          backgroundColor="#EAEAF1"
          alt="BuyBitcoin - Web app dashboard page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image02.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#EAEAF1">
        <Img
          backgroundColor="#EAEAF1"
          alt="BuyBitcoin - Web app Buy transaction page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image03.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#EAEAF1">
        <Img
          backgroundColor="#EAEAF1"
          alt="BuyBitcoin - Web app Buy and Sell page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image04.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#EAEAF1">
        <Img
          backgroundColor="#EAEAF1"
          alt="BuyBitcoin - Web app dropdown selection currency"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image05.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#EAEAF1">
        <Img
          backgroundColor="#EAEAF1"
          alt="BuyBitcoin - Web app modal confirmation"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image06.childImageSharp.fluid}
        />
      </ContainerImage>

      <Block>
        <h2>Buybitcoin Concept Exploration</h2>
        <p>
          As the product thrives, the company ideates a more advanced trading platform and enables
          various digital asset trading. Highlights one of the concept explorations mockup for
          website's app and of the landing page.
        </p>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#EAEAF1">
        <Img
          backgroundColor="#EAEAF1"
          alt="BuyBitcoin - Exchange website landing page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image07.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#EAEAF1">
        <Img
          backgroundColor="#EAEAF1"
          alt="BuyBitcoin - Exchange web app dashboard ui"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image08.childImageSharp.fluid}
        />
      </ContainerImage>
    </>
  )
}

export default BuyBitcoinContent
