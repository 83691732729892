/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Block from "../components/block"
import Spacer from "../components/spacer"
import ContainerImage from "../components/container-image"

const RebitWebContent = () => {
  const data = useStaticQuery(graphql`
    query {
      image01: file(relativePath: { eq: "rebit-web/view-sendmoney1.jpg" }) {
        ...imageLargeHeroProp
      }
      image02: file(relativePath: { eq: "rebit-web/view-pages.jpg" }) {
        ...imageLargeHeroProp
      }
      image03: file(relativePath: { eq: "rebit-web/view-sendmoney2.jpg" }) {
        ...imageLargeHeroProp
      }
      image04: file(relativePath: { eq: "rebit-web/view-sendmoney3.jpg" }) {
        ...imageLargeHeroProp
      }
      image05: file(relativePath: { eq: "rebit-web/view-sendmoney4.jpg" }) {
        ...imageLargeHeroProp
      }
      image06: file(relativePath: { eq: "rebit-web/view-transactions.jpg" }) {
        ...imageLargeHeroProp
      }
      image07: file(relativePath: { eq: "rebit-web/view-landing.jpg" }) {
        ...imageLargeHeroProp
      }
    }
  `)

  return (
    <>
      <Block>
        <p>
          <OutboundLink href="https://rebit.ph">Rebit</OutboundLink> allows anyone to transfer money
          to the Philippines from anywhere in the world using Bitcoins. It's a flagship product of{" "}
          <OutboundLink href="https://sci.ph">SCI</OutboundLink>, the parent company, launched in
          August 2014. As part of a <Link to="/work/satoshi-citadel-industries/">rebranding</Link>,
          we delivered the next version for the website and web app to align with the parent
          company's objectives. The user interface simplified and with stepper that conveys
          progress. Instead of stock photos, we used illustrations, especially hero illustrations
          for each country and spot, to complement the overall visual consistency.
        </p>
        <p>
          The mockups and user flow completed, but it didn't reach the production phase. However, it
          received a sign-off as a design system for different products.
        </p>
        <Spacer />
      </Block>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Rebit Web - Web app Send Money page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image01.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Rebit Web - Web app send money, buy load, pay bills page"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image02.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Rebit Web - Web app transaction confirmation"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image03.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Rebit Web - Web app send money transaction summary"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image04.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Rebit Web - Web app transaction history"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image05.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Rebit Web - Web app transaction history"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image06.childImageSharp.fluid}
        />
      </ContainerImage>

      <ContainerImage bgColor="#E5EAF1">
        <Img
          backgroundColor="#E5EAF1"
          alt="Rebit Web - Web app transaction history"
          placeholderClassName="gatsby-image-placeholder"
          fluid={data.image07.childImageSharp.fluid}
        />
      </ContainerImage>
    </>
  )
}

export default RebitWebContent
